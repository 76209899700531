import request from '@/utils/request'

const tradeClientMaterialAttachmentApi = {
  trade_client_material_attachment_list: '/trade/client_material_attachment/',
  trade_client_material_attachment_create: '/trade/client_material_attachment/',
  trade_client_material_attachment_update: '/trade/client_material_attachment/',
  trade_client_material_attachment_delete: '/trade/client_material_attachment/'
}

/**
 * 列表
 */
export function client_material_attachment_list (parameter) {
  return request({
    url: tradeClientMaterialAttachmentApi.trade_client_material_attachment_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function client_material_attachment_create (parameter) {
  return request({
    url: tradeClientMaterialAttachmentApi.trade_client_material_attachment_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function client_material_attachment_update (parameter, client_material_attachment_id) {
  return request({
    url: tradeClientMaterialAttachmentApi.trade_client_material_attachment_update + client_material_attachment_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function client_material_attachment_delete (client_material_attachment_id) {
  return request({
    url: tradeClientMaterialAttachmentApi.trade_client_material_attachment_delete + client_material_attachment_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
